import React from "react"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import {
  Section,
} from "../../styledComponents/section"
import permissionToSleep from "../../fixtures/permission-to-sleep"
import AudioSleepCard from "../../components/App/audioSleepCard"

import {
  StyledAudio,
} from "../../styledComponents/app/audioCard"

const PermissionToSleep = ({ data, location }) => {
  const identity = useIdentityContext()
  const sleep = permissionToSleep
  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />x
    </Helmet>
    <Layout location={location} isHomePage mode="night">
        <Section>
        {identity.user && identity.user.app_metadata?.roles?.includes('permission-reset-sleep') && 
          <StyledAudio>
          {
            sleep[0].audioFiles.map(audio => {
              let key = audio.title.toLowerCase().replace(/\s/g, '')
              return (
                <AudioSleepCard 
                  key={key}
                  title={audio.title}
                  image={audio.image}
                  content={audio.content}
                  // audioMp3={audio.audioMp3}
                  // duration={audio.duration} 
                />     
              )
            })
          }
          </StyledAudio>
        }
        </Section>
    </Layout>
    </>
  )
}

export default PermissionToSleep
